section.personal{
	padding: 120px 0;
	h2{
		font-size: 36px;
		font-weight: 700;
		margin-bottom: 15px;
	}

	p{
		margin-bottom: 25px;
		font-size: 28px;
	}
	ul{
		margin-bottom: 30px;
		padding-left: 25px;
		line-height: 24px;
		font-size: 18px;
	}
	.personal__wrapper{
		box-shadow: 1px 1px 4px rgb(221, 221, 221);
		background: white;
		padding: 40px 80px;
		border-radius: 22px;
	}
}

@media all and (max-width: 640px) {
	section.personal{
		padding: 80px 0;
		h2{
			font-size: 28px;
			font-weight: 700;
			margin-bottom: 15px;
		}
	
		p{
			margin-bottom: 25px;
			font-size: 18px;
		}
		ul{
			margin-bottom: 30px;
			padding-left: 25px;
			line-height: 24px;
			font-size: 18px;
		}
		.personal__wrapper{
			padding: 30px 20px;
		}
	}
}