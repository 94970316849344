.LegalAgreement{
	padding: 50px 0;

	h1{
		text-align: center;
		font-size: 28px;
		margin-bottom: 30px;
	}

	ol.nav{
		margin-left: 28px;
		text-transform: uppercase;
	}

	h2{
		text-transform: uppercase;
	}
	h2, h3{
		margin-bottom: 1.2rem;
		margin-top: 1.6rem;
	}

	ul, ol{
		margin-left: 22px;
		margin-bottom: 1rem;
		font-size: 18px;
	}

	a{
		color: blue;
	}

	p{
		margin-bottom: 1rem;
		font-size: 18px;
		text-align: justify;
	}
}