.pagination{
	display: flex;
	justify-content: center;
	gap: 5px;
	.pagination__button{
		width: 36px;
		height: 36px;
		border-radius: 5px;
		cursor: pointer;
		border: none;
		font-size: 18px;
		color: black;
		font-weight: 700;
		border: 1px solid #00B0F2;
	}
	.pagination__button.active{
		color: white;
		background: #00B0F2;
	}
	.pagination__ellipsis{
		color: #00B0F2;
		line-height: 18px;
		font-size: 18px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}