section.service-top{
	min-height: 120px;
	padding: 22px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	p{
		text-align: center;
		font-size: 28px;
		font-weight: 700;
		color: white;
	}
}

@media all and (max-width: 1024px) {
	section.service-top{
		p{
			font-size: 20px;
		}
	}	
}