section.law-service{
	padding: 60px 0;
	background: url("../../../assets/img/home-service.webp") center center / cover no-repeat;

	h1{
		text-align: center;
		font-size: 36px;
		margin-bottom: 20px;
	}
	p{
		text-align: center;
		font-size: 24px;
		margin-bottom: 30px;
	}
	.law-service__cards{
		display: flex;
		gap: 30px;
		.law-service__card{
			display: flex;
			flex-direction: column;
			background: white;
			width: calc(50% - 15px);
			padding: 40px;
			border-radius: 22px;
			button{
				margin-top: auto;
			}
		}
		h2{
			font-size: 28px;
			font-weight: 400;
		}
	}	

	ul{
		font-size: 18px;
		padding-left: 25px;
		list-style: url("../../../assets/icons/list-marker.svg");
		margin-bottom: 25px;
		li{
			margin: 14px 0;
		}
		ul{
			list-style: disc;
			li{
				margin: 6px 0;
			}
		}
	}
}

@media all and (max-width: 840px) {
	section.law-service{
		h1{
			font-size: 28px;
		}
		p{
			font-size: 18px;
		}
		ul{
			margin-bottom: 0;
		}
		.law-service__cards{
			flex-direction: column;
			gap: 20px;
			.law-service__card{
				padding:  30px 20px;
				width: 100%;
			}
		}
	}
}