section.payment{
	margin: 120px 0;

	h1{
		text-align: center;
		margin-bottom: 55px;
	}
	.payment__card{
		border: 1px solid #4D4D87;
		max-width: 320px;
		width: 100%;
		box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
		.payment__card-number{
			font-size: 96px;
			text-align: center;
			overflow: hidden;
			font-weight: bold;
			margin: 20px 0;
		}
		p{
			font-size: 20px;
			text-align: center;
			padding: 0 20px;
		}

		img{
			display: block;
			margin: 20px auto;
		}
	}
	.payment__wrapper{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 40px;
	}
	.payment__card:nth-child(2){
		transform: translateY(-15px);
	}
	.payment__card:nth-child(4){
		img{
			margin-right: 0;
			right: -4px;
			position: relative;
		}
	}
	.payment__card:nth-child(5){
		img{
			margin: 20px 0;
			width: calc(100% + 8px);
			left: -4px;
			object-fit: cover;
			position: relative;
		}
	}

	.payment__form{
		display: flex;
		flex-direction: column;
		gap: 10px;
		max-width: 420px;
		margin: 0 auto;
		margin-top: 80px;
		margin-bottom: 70px;
		padding: 0 10px;
	}

	.btn{
		margin: 10px auto 10px auto;
		background: #08083B;
		width: 100%;
		height: 48px;
	}

	.payment__more-price{
		display: block;
		font-size: 24px;
		font-weight: bold;
		text-align: center;
	}

	.payment-easy{
		font-size: 20px;
		text-align: center;
		a{
			text-decoration: underline;
			color: #00B0F2;
			font-style: italic;
			font-weight: bold;
		}
	}

	.payment__info{
		font-size: 24px;
		margin: 20px 0;
		display: block;
		text-align: center;
		img{
			position: relative;
			bottom: -2px;
		}
	}

	h2{
		font-size: 26px;
		margin-bottom: 10px;
	}

}

@media all and (max-width: 1060px) {
	section.payment{
		margin: 60px 0;
		h1{
			text-align: center;
			margin-bottom: 40px;
		}
		.payment__card:nth-child(2){
			transform: translateY(0);
		}
	}
}