.footer{
	background: #001F2B;
	padding: 40px 0;
	a, .logo span, a span{
		color: white;
	}

	.footer__top, .footer__top-wrapper, .row{
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
	}

	.footer__top-contacts{
		font-size: 20px;
		display: flex;
		gap: 20px;
		font-weight: 700;
	}
	
	.footer__top-wrapper{
		gap: 20px;
	}
	
	.footer__middle{
		display: flex;
		gap: 20px;
		justify-content: flex-end;
		margin-top: -15px;
	}

	.nav-link{
		font-size: 18px;
		text-decoration: underline;
	}

	.footer__bottom-link{
		display: flex;
		gap: 20px;
		align-items: center;
	}

	.footer__bottom{
		display: flex;
		justify-content: space-between;
		gap: 20px;
		margin-top: 25px;
		p{
			color: white;
			font-size: 18px;
		}
	}
}

@media all and (max-width: 1260px) {
	.footer{
		.footer__bottom{
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		.footer__top{
			flex-direction: column;
		}
		.footer__middle{
			justify-content: center;
			margin-top: 25px;
		}
	}
}

@media all and (max-width: 980px) {
	.footer{
		.footer__bottom-link{
			flex-direction: column;
			text-align: center;
		}
	}
}


@media all and (max-width: 640px) {
	.footer{
		.footer__top-wrapper{
			flex-direction: column;
		}
		.footer__top-contacts{
			flex-direction: column;
		}
		.logo__second{
			display: none;
		}
		.logo{
			margin-bottom: 20px;
		}
		.logo img{
			width: 87px;
			height: 74px;
		}
		.logo__first{
			margin-left: -15px;
		}
		.logo__first-top{
			font-size: 18px;
		}
		.logo__first-bottom{
			font-size: 36px;
		}
	}
}