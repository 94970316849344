@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

@font-face {
	font-family: "TimesNewRomanPS";
	src: url("./font/TimesNewRomanPS-BoldMT.eot");
	src:
		url("./font/TimesNewRomanPS-BoldMT.eot?#iefix") format("embedded-opentype"),
		url("./font/TimesNewRomanPS-BoldMT.woff") format("woff"),
		url("./font/TimesNewRomanPS-BoldMT.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "TimesNewRomanPS";
	src: url("./font/TimesNewRomanPS-BoldItalicMT.eot");
	src:
		url("./font/TimesNewRomanPS-BoldItalicMT.eot?#iefix") format("embedded-opentype"),
		url("./font/TimesNewRomanPS-BoldItalicMT.woff") format("woff"),
		url("./font/TimesNewRomanPS-BoldItalicMT.ttf") format("truetype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "TimesNewRomanPS";
	src: url("./font/TimesNewRomanPS-ItalicMT.eot");
	src:
		url("./font/TimesNewRomanPS-ItalicMT.eot?#iefix") format("embedded-opentype"),
		url("./font/TimesNewRomanPS-ItalicMT.woff") format("woff"),
		url("./font/TimesNewRomanPS-ItalicMT.ttf") format("truetype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "TimesNewRomanPS";
	src: url("./font/TimesNewRomanPSMT.eot");
	src:
		url("./font/TimesNewRomanPSMT.eot?#iefix") format("embedded-opentype"),
		url("./font/TimesNewRomanPSMT.woff") format("woff"),
		url("./font/TimesNewRomanPSMT.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

* {
	margin: 0;
	padding: 0;
	font-family: "Times New Roman", Times, serif;
	box-sizing: border-box;
	color: #08083b;
}

#root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
}

html {
	scroll-behavior: smooth;
	scroll-padding-top: 30px;
}

body {
	background: #f6f6f6;
	overflow: auto;
}

.fixed-body {
	overflow: hidden;
}

body::-webkit-scrollbar {
	background: #e0e0e0;
	width: 12px;
}

body::-webkit-scrollbar-thumb {
	border: 2px solid #e0e0e0;
	border-radius: 5px;
	background: #08083b;
}

.btn {
	background: none;
	border: none;
	cursor: pointer;
}

a {
	color: inherit;
	text-decoration: none;
}

.container {
	max-width: 1260px;
	margin: 0 auto;
	padding: 0 10px;
}

.btn-main {
	background: #00b0f2;
	color: white;
	height: 46px;
	font-size: 20px;
	font-weight: bold;
	border-radius: 5px;
	width: 220px;
	transition: all 0.2s;
	display: block;
}

.btn-main:hover {
	background: #0099d0;
}

.logo {
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	gap: 20px;
}

.logo span {
	display: block;
}

.logo__first-top {
	font-size: 20px;
}

.logo__first {
	margin-left: -30px;
}

.logo__first-bottom {
	font-size: 46px;
	font-weight: 600;
}
.logo__second {
	font-size: 18px;
}

footer {
	margin-top: auto;
}

@media all and (max-width: 640px) {
	.btn-main {
		height: 38px;
		font-size: 18px;
	}
}
