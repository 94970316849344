section.hero-blog{
	margin: 60px 0;
	h1{
		font-size: 48px;
		text-align: center;
		text-transform: uppercase;
		width: fit-content;
		margin: 0 auto;
		margin-bottom: 40px;

		&::after{
			content: "";
			display: block;
			height: 2px;
			width: 100%;
			background: #00B0F2;
		}
	}

	.hero-blog__wrapper{
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 20px;
	}

	.hero-blog__nav{
		background: #02232F;
		border-radius: 20px;
		padding: 20px;
		h2{
			font-size: 24px;
			color: white;
			padding: 20px 0 10px 0;
			margin-bottom: 10px;
			border-bottom:  1px solid #333A45;
		}
		form{	
			display: flex;
			gap: 10px;
			align-items: center;
		}

		.search-article{
			display: block;
			height: 44px;
			width: 44px;
			border: none;
			background: none;
			cursor: pointer;
			border-radius: 5px;
			overflow: hidden;
			flex-shrink: 0;
		}
		input{
			display: block;
			width: 100%;
			height: 44px;
			border-radius: 5px;
			font-size: 18px;
			border: none;
			padding: 0 10px;
		}
		ul{
			padding-left: 20px;
			display: flex;	
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 6px;
			list-style: url("../../../assets/icons/list-marker.svg");
			li{
				width: calc(50% - 3px);
			}
			button{
				text-align: left;
				font-size: 16px;
				cursor: pointer;
				color: white;
				border: none;
				background: none;
			}

			button.active{
				color: #00B0F2;
			}

			button:hover{
				text-decoration: underline;
			}
		}
		
	}
}

@media all and (max-width: 1024px) {
	section.hero-blog{
		margin: 60px 0;
		h1{
			font-size: 32px;
			margin-bottom: 30px;
		}
	
		.hero-blog__wrapper{
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 20px;
		}
	}
}

@media all and (max-width: 768px) {
	section.hero-blog{
		.hero-blog__nav{
			ul{
				li{
					width: 100%;
				}
			}
		}
	}
}

@media all and (max-width: 640px) {
	section.hero-blog{
		margin: 60px 0;
		h1{
			font-size: 32px;
			margin-bottom: 30px;
		}
	
		.hero-blog__wrapper{
			display: grid;
			grid-template-columns: 1fr;
			row-gap: 20px;
		}
	}
}