section.guarantee{
	margin: 120px 0;
	h1{
		font-size: 32px;
	}
	h2{
		font-size: 32px;
		text-transform: uppercase;
		text-align: center;
		margin-bottom: 42px;
	}
	.subtitle{
		font-size: 24px;
		margin: 12px 0 60px 0;
	}

	.container{
		max-width: 1110px;
	}

	.guarantee__wrapper{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 30px;
	}

	.guarantee__card{
		box-shadow: 1px 1px 4px rgb(221, 221, 221);
		max-width: 530px;
		position: relative;
		img{
			object-fit: cover;
		}
		.guarantee__card-text{
			transition: all 0.3s;
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			background: rgba(255, 255, 255, 0.85);
			width: 100%;
			height: 70px;
			font-size: 20px;
			font-weight: bold;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			p{
				padding: 0 30px;
			}
		}
	}

	.guarantee__card:hover{
		.guarantee__card-text{
			height: 100%;
		}
	}
}

@media all and (max-width: 640px) {
	section.guarantee{
		margin: 60px 0;
	}
}