section.hero{
	background: url("../../../assets/img/hero.webp") center center/cover no-repeat ;
	h1{
		font-size: 48px;
		font-weight: bold;
	}
	.hero__wrapper{
		height:  calc(100vh - 112px - 41px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;
		max-width: 1000px;
	}
	p{
		font-size: 24px;
		position: relative;
		padding-left: 10px;
	}

	p::before{
		content: "";
		width: 4px;
		height: 100%;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background: #01abe9;
	}
 
	.special{
		font-weight: 700;
		font-style: italic;
	}
	button{
		margin-top: 10px;
	}
}

@media all and (max-width: 640px) {
	section.hero{
		h1{
			font-size: 28px;
		}
		.hero__wrapper{
			height:  calc(100vh - 72px);
		}
		p{
			font-size: 16px;
		}
	}
}