section.top-article{
	h1{
		color: white;
		font-size: 32px;
		text-align: center;
	}
	figure{
		display: block;
		margin: 0 auto;
		width: 280px;
		height: 2px;
		background: #00B0F2;
	}
	.top-article__category{
		font-size: 20px;
		text-align: center;
		color: white;
	}
	.top-article__wrapper{
		padding: 25px 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 20px;
	}
	.top-article__row{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		column-gap: 60px;
		row-gap: 20px;
	}
	.top-article__date{
		font-size: 20px;
		color: white;
	}
	.top-article__share{
		display: flex;
		align-items: center;
		gap: 8px;
		font-size: 20px;
		span{
			color: white;
			display: block;
			margin-right: 15px;
		}
	}
}

@media all and (max-width: 728px) {
	section.top-article{
		h1{
			font-size: 24px;
		}
		.top-article__category{
			font-size: 1.1em;
		}
		.top-article__date{
			font-size: 1.1em;
		}
		.top-article__share{
			font-size: 1.1em;
		}
		.top-article__wrapper{
			gap: 15px;
		}
	}
}

