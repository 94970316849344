section.services{
	margin: 120px 0;
	img{
		object-fit: cover;
	}
	.services__wrapper{
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 30px;
	}
	.services__card{
		border-radius: 4px;
		width: 100%;
		max-width: 500px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: white;
		box-shadow: 1px 1px 4px rgb(221, 221, 221);
	}
	h2{
		margin: 20px 0 12px 0;
		text-align: center;
		padding:  0 10px;
		font-size: 24px;
	}
	.subtitle{
		text-align: center;
		margin-bottom: 12px;
		font-size: 18px;
		font-weight: bold;
	}
	ul{
		padding: 0 30px 0 55px;
		font-size: 16px;
	}
	a{
		margin-top: auto;
		display: block;
	}
	button{
		margin: 20px auto;
	}

}

@media all and (max-width: 640px) {
	section.services{
		margin: 60px 0;
		h2{
			font-size: 20px;
		}
	}
}