section.algorithm{
	margin: 120px 0;
	h2{
		text-align: center;
		font-size: 24px;
		margin-bottom: 40px;
	}
	.algorithm__wrapper{
		display: flex;
		justify-content: center;
		gap: 30px;
		flex-wrap: wrap;
	}
	.algorithm__card{
		box-shadow: 1px 1px 4px rgb(221, 221, 221);
		width: 320px;
		padding: 20px;
		border-radius: 5px;
		background: white;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		gap: 20px;
		p{
			font-size: 20px;
			height: 68px;
		}
	}
	.algorithm__link{
		font-size: 24px;
		text-align: center;
		margin: 40px 0 20px 0;
		a{
			font-size: 24px;
			font-weight: bold;
		}
	}
}

@media all and (max-width: 640px) {
	section.algorithm{
		margin: 80px 0;
	}
}