section.calculate{
	margin: 120px 0;
	.container{
		max-width: 980px;
	}
	h2{
		text-align: center;
		font-size: 32px;
	}
	h3{
		font-size: 24px;
		margin-bottom: 30px;
		text-align: center;
	}
	h4{
		text-align: center;
		font-size: 20px;
	}
	.calculate-text{
		font-size: 20px;
		margin: 12px 0 40px 0;
		text-align: center;
	}
	.calculate__wrapper{
		display: flex;
		justify-content: center;
		gap: 30px;
		flex-wrap: wrap;
		margin-bottom: 30px;
	}
	.calculate__card{
		max-width: 300px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px;
		border: 1px solid #00B0F2;
		border-radius: 5px;
		background: white;
	}
	.calculate__texts{
		p{
			font-size: 20px;
			margin: 20px 0;
			text-align: center;
		}
	}
}

@media all and (max-width: 640px) {
	section.calculate{
		margin: 80px 0;
	}
}