.article-content{
	margin: 60px 0;
	font-size: 18px;

	.content__wrapper{
		h1, h2, h3, h4, h5, h6 {
			margin-top: 20px;
			margin-bottom: 10px;
			line-height: 1.3;
			color: #222;
		 }
		 
		 h1 {
			font-size: 2.5em;
			font-weight: bold;
		 }
		 
		 h2 {
			font-size: 2em;
		 }
		 
		 h3 {
			font-size: 1.75em;
		 }
		 
		 h4, h5, h6 {
			font-size: 1.5em;
		 }
		 
		 p {
			font-size: 1.1em;
			margin-bottom: 0.5em;
		 }
		 
		 ul, ol {
			margin: 1em 0;
			font-size: 1.1em;
			padding-left: 40px;
		 }
		 
		 li {
			margin-bottom: 0.5em;
		 }
		 
		 a {
			color: #007bff;
			font-size: 1.1em;
			text-decoration: none;
		 }
		 
		 a:hover {
			text-decoration: underline;
		 }
		 
		 blockquote {
			font-size: 1.1em;
			margin: 1.5em 0;
			padding: 10px 20px;
			background-color: #f1f1f1;
			border-left: 5px solid #007bff;
			font-style: italic;
			color: #555;
		 }
		 
		 table {
			width: 100%;
			border-collapse: collapse;
			margin: 1em 0;
			font-size: 1em;
			background-color: #fff;
		 }
		 
		 table th, table td {
			border: 1px solid #ddd;
			padding: 10px;
			text-align: left;
		 }
		 
		 table th {
			background-color: #007bff;
			color: #fff;
			font-weight: bold;
		 }
		 
		 table tr:nth-child(even) {
			background-color: #f9f9f9;
		 }
		 
		 img {
			max-width: 100%;
			height: auto;
			border-radius: 5px;
		 }
	}
}